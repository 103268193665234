import React, {useEffect} from 'react';
import {isMobile} from "react-device-detect";
import {adDisplayDelay, delayMultiplicationFactor} from "../../utils/articleUtil";

const AdHeader = () => {
    useEffect(() => {
        window.googletag = window.googletag || {cmd: []};
        const timeout = setTimeout(() => {
            if (window.googletag) {
                googletag.cmd.push(function () {
                    try {
                        googletag.display('div-at_leaderboard_atf');
                    } catch (e) {

                    }
                });
            }
        }, adDisplayDelay() * delayMultiplicationFactor(isMobile));
        return () => {
            clearTimeout(timeout);
        };
    }, []);

    return <div className="ad-container mb-2" data-datocms-noindex>
        <div className="advertisement-text">Advertisement</div>
        <div id="div-at_leaderboard_atf"></div>
    </div>
}

export default AdHeader;
