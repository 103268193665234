import React from 'react'
import {withPrefix} from 'gatsby'
import Helmet from 'react-helmet'
import favicon from '../../resources/favicon.png'
import queryString from 'query-string'

import {metadata} from '../../../config.js'
import {isMobile} from 'react-device-detect'
import HeaderWrapper from '../header'
import FooterWrapper from '../footer'

import {
  delayMultiplicationFactor,
  getCookie,
  googleScriptLoadingDelay,
  isBrowser,
  setCookie
} from '../../utils/articleUtil'
import consts from '../../constants/consts'

import '../../css/index.scss'
import jwt_decode from 'jwt-decode'
import {STRINGS} from '../../constants/strings'
import SubscribePopupWrapper from '../subscribe/SubscribePopup'
import {URL} from '../../constants/urls'
import {FloatingBanner, FloatingBannerSmall} from '@cg-squad/ui-components'

class Layout extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            categoryPath: this.categoryPath,
            subCategoryPath: this.subCategoryPath,
            timeouts: [],
            timeoutMultiplier: 1,
            isSubscribeOpen: false,
            propsIsSubscribeOpen: undefined,
            showMarketingCheckbox: false,
            isScrolling: false
        }
        this.scrollTimeout = null;
        this.onScrollEventListener = this.onScrollEventListener.bind(this);
    }

    static getDerivedStateFromProps(props, current_state) {
      if (current_state.propsIsSubscribeOpen !== props.isSubscribeOpen) {
        return {
          isSubscribeOpen: props.isSubscribeOpen,
          propsIsSubscribeOpen: props.isSubscribeOpen
        }
      }
      return null;
    }

    handleGoogleSignInCredentialResponse(response) {
      document.removeEventListener('scroll', this.onScrollEventListener);
      if (response.credential) {
        const decoded = jwt_decode(response.credential);
        fetch(`${URL.AGE_HOLDINGS}/api/subscribe?name=${decoded.name}&email=${decoded.email}&source=${window.location.pathname}&marketing=true&website=${STRINGS.SUBSCRIPTION_TAG}`)
          .then(resp => resp.json())
          .then(res => {
            setCookie('google_sign_in_prompt_used',true);
            alert('Successfully subscribed to our newsletter');
          });
      }
    }

    onScrollEventListener = (event) => {
      const object = document.getElementById('credential_picker_container');
      if (!object) {
        return;
      }

      clearTimeout(this.scrollTimeout);
      const {isScrolling} = this.state;

      if (!isScrolling) {
        this.setState({isScrolling: true});
        object.style.opacity = '0.4';
      }

      this.scrollTimeout = setTimeout(() => {
        this.setState({isScrolling: false});
        object.style.opacity = '1';
      }, 80);
    }

    initializeGsi() {
      if (getCookie('google_sign_in_prompt_used') == 'true') {
        return;
      }
      if (!document.querySelector('link[href*="gsi/client"]')) {
        const headElement = document.getElementsByTagName("head")[0];
        const self = this;

        const gsiScript = document.createElement("script");
        gsiScript.src = `https://accounts.google.com/gsi/client`;
        gsiScript.type = "text/javascript";
        gsiScript.onload = () => {
          google.accounts.id.initialize({
            client_id: "1064896852139-d0b43mt5n4g5iun53bbb7dpqilrsp9j1.apps.googleusercontent.com",
            callback: self.handleGoogleSignInCredentialResponse.bind(self),
            state_cookie_domain: 'agetimes.co.uk',
            /*prompt_parent_id: 'google-singin-prompt',*/
            cancel_on_tap_outside: false,
            context: "signin"
          });
          /*google.accounts.id.renderButton(
              document.getElementById("buttonDiv"),
              { theme: "outline", size: "large" }  // customization attributes
          );*/
          google.accounts.id.prompt((notification) => {
            if (notification.isNotDisplayed()) {
              console.log(notification.getNotDisplayedReason());
            }
            if (notification.isDisplayed()) {
              /*console.log("=============");
              setTimeout(() => {
                  const container = document.getElementById('google-singin-prompt');
                  container.insertAdjacentHTML('beforeend', `<div class="px-2 pb-2 leading-snug" style="color: #5f6368; font-size: 14px; font-family: 'Google Sans';">
              <input type="checkbox" id="marketing" checked/>
              <label htmlFor="marketing">
                  I’m happy for Pension Times to send me selected marketing information which may be of interest to me.
              </label></div>`)
              });*/
              document.addEventListener('scroll', this.onScrollEventListener);
            }
            if (notification.isDismissedMoment()) {
              console.log(notification.getDismissedReason());
              document.removeEventListener('scroll', this.onScrollEventListener);
            }
          });
        }
        headElement.appendChild(gsiScript);
      }
    }

    componentDidMount() {
        const headElement = document.getElementsByTagName("head")[0];

        const _this = this;
        const timeoutMultiplier = delayMultiplicationFactor(isMobile);
        this.setState({timeoutMultiplier});

        /*this.state.timeouts.push(setTimeout(() => {
            if (!document.querySelector('script[src*="google-funding-choices.js"]')) {
                const headElement = document.getElementsByTagName("head")[0];
                const fundingChoicesScript = document.createElement("script");
                fundingChoicesScript.src = `${withPrefix('google-funding-choices.js')}`;
                fundingChoicesScript.type = "text/javascript";
                fundingChoicesScript.async = true;
                headElement.appendChild(fundingChoicesScript);
            }
        }, consts.SCRIPT_LOADING_TIME_WAIT * timeoutMultiplier));*/

        this.state.timeouts.push(setTimeout(() => {
            this.addAccessibilityScript();
        }, consts.SCRIPT_LOADING_TIME_WAIT * (4.5 + timeoutMultiplier)));

        this.state.timeouts.push(setTimeout(() => {
            /*if (!document.querySelector('script[src*="osd.js"]')) {
                const googleActiveViewScript = document.createElement("script");
                googleActiveViewScript.async = true;
                googleActiveViewScript.src = `https://www.googletagservices.com/activeview/js/current/osd.js?cb=%2Fr20100101`;
                headElement.appendChild(googleActiveViewScript);
            }*/

            if (!document.querySelector('script[src*="gtm"]')) {
                const gtmScript = document.createElement("script");
                gtmScript.src = `${withPrefix(`gtm.js`)}`;
                gtmScript.async = true;
                headElement.appendChild(gtmScript);
            }

            /*if (!document.querySelector('script[src*="gpt.js"]')) {
                _this.addGPTScript();
            } else if (!document.querySelector('script[src*="ad.tallmanads_1.0.0.js"]')) {
                _this.addAdScript();
            } else {
                _this.refreshAd();
            }*/
        }, googleScriptLoadingDelay() * timeoutMultiplier));

        this.state.timeouts.push(setTimeout(() => {
            let showGsi = true;
            if (isBrowser() && window.location && window.location.search) {
              const query = queryString.parse(window.location.search);
              if (query.subscribe) {
                showGsi = false;
                this.onSubscriptionPopupStateChange(query.subscribe === 'true');
              }
            }
            if (showGsi) {
              this.state.timeouts.push(setTimeout(() => {
                this.initializeGsi();
              }, consts.SCRIPT_LOADING_TIME_WAIT * (timeoutMultiplier)));
            }
        }, consts.SCRIPT_LOADING_TIME_WAIT * timeoutMultiplier));

        this.state.timeouts.push(setTimeout(() => {
            this.addCookieHubScript();
        }, consts.SCRIPT_LOADING_TIME_WAIT * (2 + timeoutMultiplier)));
    }

    addGPTScript() {
        const headElement = document.getElementsByTagName("head")[0];
        const adScript = document.createElement("script");
        adScript.src = `https://securepubads.g.doubleclick.net/tag/js/gpt.js`;
        adScript.async = true;
        headElement.appendChild(adScript);
        this.addAdScript();
    }

    addAdScript() {
        const _this = this;
        const headElement = document.getElementsByTagName("head")[0];
        const adScript = document.createElement("script");
        adScript.src = `${withPrefix('ad.tallmanads_1.0.0.js')}`;
        adScript.type = "text/javascript";
        headElement.appendChild(adScript);
        adScript.onload = () => {
            this.state.timeouts.push(setTimeout(() => {
                _this.refreshAd();
            }, 200 * this.state.timeoutMultiplier));
        }
    }

    refreshAd() {
        this.state.timeouts.push(setTimeout(() => {
            try {
                window.googletag.pubads().refresh(null, {changeCorrelator: true});
            } catch (e) {
                console.log('googletag: ' + e);
            }
        }, 100));
    }

    addAccessibilityScript() {
        const accessibilityOptions = {
            animations: {buttons: false},
            modules: {
                increaseText: true,
                decreaseText: true,
                invertColors: [true / false],
                increaseTextSpacing: false,
                decreaseTextSpacing: false,
                grayHues: true,
                underlineLinks: false,
                bigCursor: true,
                readingGuide: true,
                textToSpeech: true,
                speechToText: true
            },
            icon: {
                useEmojis: true
            }
        }
        const headElement = document.getElementsByTagName("head")[0];
        const _this = this;
        if (!document.querySelector('script[src*="accessibility.min.js"]')) {
            const script = document.createElement("script");
            script.src = `${withPrefix('accessibility.min.js')}`;
            script.type = "text/javascript";
            headElement.appendChild(script);
            script.onload = () => {
                _this.state.timeouts.push(setTimeout(() => {
                    new Accessibility(accessibilityOptions);
                }, 1000));
            }
        }
    }

    addCookieHubScript() {
        const headElement = document.getElementsByTagName("head")[0];
        if (!document.querySelector('script[src*="cookiehub.net"]')) {
            const cookieScript = document.createElement("script");
            cookieScript.src = 'https://cookiehub.net/c2/2537cbc7.js';
            cookieScript.type = "text/javascript";
            cookieScript.async = true;
            headElement.appendChild(cookieScript);
            cookieScript.onload = () => {
                window.cookiehub.load();
            }
        }
    }

    componentWillUnmount() {
        this.state.timeouts.forEach(timeout => clearTimeout(timeout));
    }

    categoryPath = () => {
        return isBrowser() && window.location.pathname.split('/')[1];
    }

    subCategoryPath = () => {
        if (isBrowser()) {
            const pathArray = window.location.pathname.split('/');
            if (pathArray && pathArray[2]) {
                return pathArray[2];
            }
        }
        return false;
    }

    onSubscriptionPopupStateChange = (state) => {
        this.setState({isSubscribeOpen: state});
    }

    render() {
        return (
            <React.Fragment>
                <Helmet defaultTitle={metadata.defaultTitle} titleTemplate={metadata.titleTemplate}>
                    {this.props.preloadImage && <link rel="preload" as="image" href={this.props.preloadImage.src}
                        imagesrcset={this.props.preloadImage.webpSrcSet} imagesizes={this.props.preloadImage.sizes}/>}
                    <meta name="author" content={metadata.author}/>
                    <meta name="description" content={metadata.description}/>
                    <html lang="en"/>
                    <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8"/>
                    <meta httpEquiv="X-UA-Compatible" content="IE=edge"/>
                    <meta httpEquiv="Content-Language" content="en"/>
                    <meta property={metadata.facebookId} content="Age-Times-102410988886246"/>
                    <meta property={metadata.twitterId} content="Age_Times"/>
                    <link title="timeline-styles" rel="shortcut icon" type="image/png" href={favicon}/>
                    <link as="style" rel="stylesheet preload prefetch" href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&family=Montserrat:wght@400;600&display=swap"/>
                    <link rel="preload" href="/fonts/Helvetica.woff" as="font" crossOrigin="anonymous"/>
                    <noscript>
                        {`<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-K5K6TD4"
                            height="0" width="0" style="display:none;visibility:hidden"></iframe>`}
                    </noscript>
                </Helmet>
                <div id="outer-container">
                    <HeaderWrapper path={this.props.path}
                            categoryPath={this.state.categoryPath()}
                            subCategoryPath={this.state.subCategoryPath()}
                            onSubscriptionPopupStateChange={this.onSubscriptionPopupStateChange}/>
                    {this.props.children}
                    <FooterWrapper categoryPath={this.state.categoryPath()}
                            subCategoryPath={this.state.subCategoryPath()}
                            onSubscriptionPopupStateChange={this.onSubscriptionPopupStateChange}/>
                    <FloatingBanner className={"invisible"} onSubscriptionPopupStateChange={this.onSubscriptionPopupStateChange}/>
                    <FloatingBannerSmall className={"invisible"} onSubscriptionPopupStateChange={this.onSubscriptionPopupStateChange}/>
                    {this.state.isSubscribeOpen && <SubscribePopupWrapper onPopupClose={() => {
                      this.props.onSubscriptionPopupStateChange && this.props.onSubscriptionPopupStateChange(false)
                      this.setState({
                        isSubscribeOpen: false
                      })
                    }}/>}
                </div>
            </React.Fragment>
        )
    }
}

export default Layout
