import React, {useState} from "react";
import {STRINGS} from "../../constants/strings";
import {SubscribePopup} from '@cg-squad/ui-components';
import {metadata} from '../../../config'


function SubscribePopupWrapper(props) {
    const [loading, setLoading] = useState(false);

    const onClosePopup = () => {
        props.onPopupClose();
    }

    const logoConf = {
        img: 'pt-white.svg',
        width: 300,
        height: 80
    }

    const subscribeApi = (data) => {
        setLoading(true);
        fetch(`https://www.agegroup.io/api/subscribe?name=${data.name}&email=${data.email}&source=${window.location.pathname}&marketing=${data.marketing}&website=${STRINGS.SUBSCRIPTION_TAG}`)
            .then(resp => resp.json())
            .then(res => {
                setLoading(false);
                alert('Successfully subscribed to our newsletter');
                onClosePopup();
            });
    }

    return (
        <SubscribePopup pageName={metadata.defaultTitle} onPopupClose={props.onPopupClose} subscribe={subscribeApi} loading={loading} logoConf={logoConf} containerClass="bg-denim-300"/>
    );
}

export default SubscribePopupWrapper
