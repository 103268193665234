import React from 'react'
import {graphql, StaticQuery} from 'gatsby'
import {STRINGS} from "../../constants/strings";
import AdHeader from "../ads/adHeader";
import {URL} from "../../constants/urls";
import {FOLLOW_US} from "../../constants/followUs"
import { Header } from '@cg-squad/ui-components';

const HeaderWrapper = props => {

    const logoSizes = {
        menuLogoWidth: 250,
        menuLogoHeight: 40,
        headerLogoWidth: 188,
        headerLogoHeight: 50
    }
    
    const renderNavItems = (className) => (
        <StaticQuery
            query={graphql`
          query HeaderCategoryQuery {
              allCategories: allDatoCmsCategory(
                filter: {root: {eq: true}, website: {elemMatch: {name: {eq: "at"}}}}
                sort: {order: ASC, fields: position}
              ) {
                nodes {
                  slug
                  title
                  treeChildren {
                    slug
                    title
                    position
                  }
                }
              }
            }
        `}
            render={data =>
                <Header path={props.path}
                        categoryPath={props.categoryPath}
                        subCategoryPath={props.subCategoryPath}
                        onSubscriptionPopupStateChange={props.onSubscriptionPopupStateChange}
                        allCategories={data.allCategories} 
                        stringsConst={STRINGS} urlsConst={URL} 
                        AdHeader={AdHeader}
                        followUs={FOLLOW_US}
                        logoSizes={logoSizes}/>
            }
        />
    )

    return (
        <>
            {renderNavItems()}
        </>
    )
}

export default HeaderWrapper
